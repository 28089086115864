.cg-mosaic-gallery {

	&.cg-mosaic-gallery-grid {
		display: grid;
		grid-template-columns: var(--cols, 1fr);
		grid-template-rows: var(--rows, auto);
		gap: var(--spacing, 2px);
		max-width: var(--width, none);
	}

	&.cg-mosaic-gallery--mobile {
		grid-template-columns: var(--mobile-cols, 1fr);
		grid-template-rows: var(--mobile-rows, 1fr);
		gap: var(--mobile-spacing, 2px);
		max-width: var(--mobile-width, none);
	}

	.cg-mosaic-item {
		grid-column-start: var(--col, 1);
		grid-column-end: span var(--col-span, 1);
		grid-row-start: var(--row, 1);
		grid-row-end: span var(--row-span, 1);
		background: var(--bg, transparent);
		line-height: 1px;
	}

	&.cg-mosaic-gallery--mobile .cg-mosaic-item {
		grid-column-start: var(--mobile-col, 1);
		grid-column-end: span var(--mobile-col-span, 1);
		grid-row-start: var(--mobile-row, 1);
		grid-row-end: span var(--mobile-row-span, 1);
	}

	.cg-mosaic-item--centered {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;

		.cg-gallery-item {
			height: auto;
		}
	}

	.cg-mosaic-item--filled {
		.cg-gallery-image-part {
			flex: 1 1 auto;
			width: 100%;
		}
		.cg-gallery-item {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.cg-gallery-label {
			flex: 0 0 auto;
		}
		.cg-gallery-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.cg-gallery-item {
		height: 100%;
		line-height: 1px;
	}

	.cg-gallery-image {
		max-width: 100%;
		max-height: 100%;
		height: auto;
		width: auto;
	}

	&.cg-mosaic-gallery--mobile {
		.cg-mosaic-hide-mobile {
			display: none;
		}
	}
	&:not(.cg-mosaic-gallery--mobile) {
		.cg-mosaic-hide-desktop {
			display: none;
		}
	}

}

