.cg-columns-gallery {

	.cg-gallery-item {
		display: block;
		line-height: 1px;
		margin-bottom: var(--margin, 10px);
		width: var(--calc-w, 100%);
	}

	.cg-gallery-image {
		width: 100%;
		height: auto;
	}

	.cg-columns-sizer-box {
		display: flex;
		flex-wrap: wrap;
		column-gap: var(--margin, 10px);
	}

	.cg-columns-sizer {
		flex: 1 0 calc(var(--w, 200px) * 0.85); // Allow to be 85% of preferred width
	}

	&.cg-columns-gallery--mobile {
		.cg-columns-sizer {
			flex-basis: calc(var(--mobile-w, 200px) * 0.85);
		}
	}

	&.cg-columns-gallery--loadable {
		.cg-gallery-item {
			transition: visibility 0.3s, opacity 0.3s;
			transition-delay: calc((var(--number, 1) - 1) * 0.025s);
		}
	}

	&.cg-columns-gallery--loading {
		animation: gallery-loading-bg 0.5s infinite alternate;

		.cg-gallery-item {
			transition: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	@keyframes gallery-loading-bg {
		from {
			background-color: rgba(black, 0.03);
		}
		to {
			background-color: rgba(black, 0.05);
		}
	}
}
