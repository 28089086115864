.cg-single-gallery {
	position: relative;
	margin-right: auto;
	display: inline-block;
	max-width: 100%;
	width: var(--w, 100%);
	line-height: 1px;

	.cg-gallery-item, .cg-gallery-image {
		max-width: 100%;
		height: auto;
	}

	&.cg-single-gallery--mobile {
		width: var(--mobile-w, var(--w, 100%));
	}

}

.cg-single-gallery-count {
	position: absolute;
	right: 10px;
	bottom: 10px;
	padding: 5px 30px 5px 8px;
	line-height: 1;
	font-size: 14px;
	color: #333;
	border-radius: 2px;
	font-weight: bold;
	background: rgba(white, 0.5) url('photo.svg') no-repeat right 6px center;
	background-size: auto 16px;

}
