.cg-rows-gallery {

	line-height: 1px;

	.cg-gallery-item {
		display: inline-block;
		position: relative;
		line-height: 1px;
	}

	.cg-gallery-image-part {
		background: rgba(black, 0.05);
	}

	.cg-gallery-image {
		height: 100%;
		width: auto;
	}

	&.cg-rows-gallery--loadable {
		.cg-gallery-item {
			transition: visibility 0.3s, opacity 0.3s;
			transition-delay: calc((var(--number, 1) - 1) * 0.025s);
		}
	}

	&.cg-rows-gallery--loading {
		animation: gallery-loading-bg 0.5s infinite alternate;

		.cg-gallery-item {
			transition: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	@keyframes gallery-loading-bg {
		from {
			background-color: rgba(black, 0.03);
		}
		to {
			background-color: rgba(black, 0.06);
		}
	}


}
