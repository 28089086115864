.cg-gallery-layout--ib {
	display: inline-block;
}

.cg-gallery-layout--default.cg-gallery-margin {
	margin-top: var(--layout-margin, 3em);
	margin-bottom: var(--layout-margin, 3em);
}

.cg-gallery-layout--ac {
	display: block;
	margin-left: auto;
	margin-right: auto;

	&.cg-gallery-margin {
		margin-top: var(--layout-margin, 3em);
		margin-bottom: var(--layout-margin, 3em);
	}
}

.cg-gallery-layout--al {
	display: block;
	margin-left: 0;
	margin-right: auto;

	&.cg-gallery-margin {
		margin-top: var(--layout-margin, 3em);
		margin-bottom: var(--layout-margin, 3em);
	}
}

.cg-gallery-layout--ar {
	display: block;
	margin-left: auto;
	margin-right: 0;

	&.cg-gallery-margin {
		margin-top: var(--layout-margin, 3em);
		margin-bottom: var(--layout-margin, 3em);
	}
}

.cg-gallery-layout--fl {
	display: block;
	float: left;

	&.cg-gallery-margin {
		margin-top: var(--layout-margin, 3em);
		margin-bottom: var(--layout-margin, 3em);
		margin-right: var(--layout-margin, 3em);
	}
}

.cg-gallery-layout--fr {
	display: block;
	float: right;

	&.cg-gallery-margin {
		margin-top: var(--layout-margin, 3em);
		margin-bottom: var(--layout-margin, 3em);
		margin-left: var(--layout-margin, 3em);
	}

}
