.cg-strip-gallery {
	height: var(--h, 200px);
	position: relative;

	&.cg-strip-gallery--has-progresbar {
		padding-bottom: 6px;
	}

	&.cg-strip-gallery--has-arrows {
		padding-top: 40px;
	}

	.cg-gallery-item.swiper-slide {
		width: auto;
		line-height: 1px;
	}

	.cg-gallery-image-part {
		width: auto;
		height: 100%;
	}

	.cg-gallery-image {
		height: auto;
		width: auto;
		max-height: var(--h, 200px);
	}

	.cg-gallery-progressbar {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 3px;
	}

	&.cg-strip-gallery--mobile {
		height: var(--mobile-h, var(--h));

		.cg-gallery-image {
			max-height:var(--mobile-h, var(--h));
		}
	}
}

.cg-strip-gallery-arrows {
	position: absolute;
	top: 0;
	right: 0;
}

.cg-strip-gallery-button {
	width: 32px;
	height: 32px;
	border-radius: 32px;
	border: none;
	background: rgba(0, 0, 0, 0.05) no-repeat center center;
	background-size: 14px auto;
	transition: all 0.2s;
	margin-left: 5px;
	color: rgba(black, 0.7);
	cursor: pointer;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}

	&.swiper-button-disabled {
		opacity: 0.4;
		cursor: auto;
	}
}

.cg-strip-gallery-button--prev {
	background-image: url('chevron-left.svg');
	background-position: calc(50% - 1px) center;
}
.cg-strip-gallery-button--next {
	background-position: calc(50% + 1px) center;
	background-image: url('chevron-right.svg');
}
