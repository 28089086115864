.cg-swiper-gallery {
	width: var(--w, 100%);
	position: relative;
	max-width: 100%;

	.cg-gallery-item {
		line-height: 1px;
	}
	.cg-gallery-item, .cg-gallery-image {
		max-width: 100%;
		height: auto;
	}

}

.cg-swiper-gallery--cropped {
	.cg-gallery-item, .cg-gallery-image {
		width: var(--w);
		height: var(--h);
		object-fit: cover;
	}
	.cg-swiper-gallery-body {
		height: var(--h);
	}

	&.cg-swiper-gallery--has-labels {
		.cg-gallery-label {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.cg-gallery-item, .cg-swiper-gallery-body {
			height: calc(var(--h) + 32px);
		}
	}

}

.cg-swiper-gallery--fixed {
	.cg-gallery-item {
		width: var(--w);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: var(--fixed-bg, rgba(black, 0.2));
	}
	.cg-gallery-label {
		align-self: stretch;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.cg-swiper-gallery--mobile {
	width: var(--mobile-w, var(--w, 100%));

	&.cg-swiper-gallery--fixed {
		.cg-gallery-item {
			width: var(--mobile-w, var(--w, 100%));
		}
	}

	&.cg-swiper-gallery--cropped {

		.cg-swiper-gallery-body {
			height: var(--mobile-h, var(--h));
		}

		.cg-gallery-item, .cg-gallery-image {
			width: var(--mobile-w, var(--w));
			height: var(--mobile-h, auto);
			object-fit: cover;
		}
	}
}


.cg-swiper-gallery--has-dots {
	padding-bottom: 24px;
}

.cg-swiper-gallery-body {
	position: relative;
}

.cg-swiper-gallery-dots {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	height: 18px;
	line-height: 1px;
}

.cg-swiper-gallery-dots {

	.swiper-pagination-bullet {
		margin-left: 0;
		width: 12px;
		height: 18px;
		background: transparent;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&::after {
			display: inline-block;
			width: 6px;
			height: 6px;
			content: '';
			border-radius: 6px;
			background-color: rgba(black, 0.4);
		}
	}

	.swiper-pagination-bullet-active::after {
		background-color: rgba(black, 0.9);
	}

}

.cg-swiper-gallery-button {
	width: 40px;
	height: 40px;
	border: none;
	position: absolute;
	top: calc(50% - 20px);
	background: rgba(white, 0.4) no-repeat center center;
	background-size: 18px auto;
	transition: all 0.2s;
	color: rgba(black, 0.7);
	cursor: pointer;
	z-index: 100;

	&:hover {
		background-color: rgba(white, 0.6);
	}

	&.swiper-button-disabled {
		opacity: 0;
		visibility: hidden;
	}
}

.cg-swiper-gallery-button--prev {
	background-image: url('chevron-left.svg');
	left: 3px;
}
.cg-swiper-gallery-button--next {
	background-image: url('chevron-right.svg');
	right: 3px;
}


.cg-swiper-gallery-thumbnails {
	height: var(--thumbnail-h, 50px);
	margin-top: 2px;

	&>.swiper-container, &>.swiper-container>.swiper-wrapper {
		height: 100%;
	}
	&>.swiper-container {
		padding-top: 1px;
		padding-bottom: 1px;
	}
	.swiper-slide {
		width: auto;
	}

	.cg-gallery-image-part {
		height: 100%;
	}

	.cg-gallery-item--playable .cg-gallery-image-part::after {
		//display: none;
		height: 32px;
		width: 32px;
		left: calc(50% - 16px);
		top: calc(50% - 16px);
		max-width: 80%;
		max-height: 80%;
	}

	.cg-gallery-item--playable:hover::after {
		//transform: none;
		//opacity: inherit;
	}

}
.cg-swiper-gallery-thumbnail {
	display: inline-block;
	margin-right: 2px;
	height: 100%;
	vertical-align: middle;
	background: rgba(black, 0.1);
}
.cg-swiper-gallery-thumbnail-image {
	height: 100%;
	width: auto;
}
.cg-swiper-gallery-thumbnail--active {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		left: -1px;
		top: -1px;
		right: -1px;
		bottom: -1px;
		border: solid 2px red;
		z-index: 22;
		pointer-events: none;
	}
}
