.cg-gallery-item {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cg-gallery-image-part {
  position: relative;
  overflow: hidden;
}

.cg-gallery-item--playable .cg-gallery-image-part::after {
  content: "";
  width: 50px;
  height: 50px;
  background: url("play.svg") no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  transform-origin: center center;
  transition: all 0.3s;
  opacity: 0.667;
}
.cg-gallery-item--playable:hover .cg-gallery-image-part::after {
  transform: scale(1.4);
  opacity: 1;
}

@media (hover: hover) {
  .cg-gallery-hover-fx .cg-gallery-item .cg-gallery-image {
    transition: transform 0.3s;
  }
  .cg-gallery-hover-fx .cg-gallery-item:hover .cg-gallery-image {
    transform: scale(1.075);
  }
}
.cg-gallery-enlarged-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
  pointer-events: none;
}

.cg-gallery-enlarged-container-html {
  pointer-events: auto;
  max-width: 100vw;
  max-height: calc(100vh - 50px);
}

.cg-gallery-enlarged-video {
  max-width: 100vw;
  max-height: 85vh;
  pointer-events: auto;
}

.cg-gallery-enlarged-iframe {
  max-width: 100vw;
  max-height: 85vh;
  width: 1024px;
  height: 576px;
  pointer-events: auto;
  border: none;
}

.cg-gallery-label {
  line-height: 1.3;
  font-size: 14px;
  padding: 6px 0;
}

.cg-rows-gallery {
  line-height: 1px;
}
.cg-rows-gallery .cg-gallery-item {
  display: inline-block;
  position: relative;
  line-height: 1px;
}
.cg-rows-gallery .cg-gallery-image-part {
  background: rgba(0, 0, 0, 0.05);
}
.cg-rows-gallery .cg-gallery-image {
  height: 100%;
  width: auto;
}
.cg-rows-gallery.cg-rows-gallery--loadable .cg-gallery-item {
  transition: visibility 0.3s, opacity 0.3s;
  transition-delay: calc((var(--number, 1) - 1) * 0.025s);
}
.cg-rows-gallery.cg-rows-gallery--loading {
  animation: gallery-loading-bg 0.5s infinite alternate;
}
.cg-rows-gallery.cg-rows-gallery--loading .cg-gallery-item {
  transition: none;
  visibility: hidden;
  opacity: 0;
}
@keyframes gallery-loading-bg {
  from {
    background-color: rgba(0, 0, 0, 0.03);
  }
  to {
    background-color: rgba(0, 0, 0, 0.06);
  }
}

.cg-columns-gallery .cg-gallery-item {
  display: block;
  line-height: 1px;
  margin-bottom: var(--margin, 10px);
  width: var(--calc-w, 100%);
}
.cg-columns-gallery .cg-gallery-image {
  width: 100%;
  height: auto;
}
.cg-columns-gallery .cg-columns-sizer-box {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--margin, 10px);
}
.cg-columns-gallery .cg-columns-sizer {
  flex: 1 0 calc(var(--w, 200px) * 0.85);
}
.cg-columns-gallery.cg-columns-gallery--mobile .cg-columns-sizer {
  flex-basis: calc(var(--mobile-w, 200px) * 0.85);
}
.cg-columns-gallery.cg-columns-gallery--loadable .cg-gallery-item {
  transition: visibility 0.3s, opacity 0.3s;
  transition-delay: calc((var(--number, 1) - 1) * 0.025s);
}
.cg-columns-gallery.cg-columns-gallery--loading {
  animation: gallery-loading-bg 0.5s infinite alternate;
}
.cg-columns-gallery.cg-columns-gallery--loading .cg-gallery-item {
  transition: none;
  visibility: hidden;
  opacity: 0;
}
@keyframes gallery-loading-bg {
  from {
    background-color: rgba(0, 0, 0, 0.03);
  }
  to {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.cg-strip-gallery {
  height: var(--h, 200px);
  position: relative;
}
.cg-strip-gallery.cg-strip-gallery--has-progresbar {
  padding-bottom: 6px;
}
.cg-strip-gallery.cg-strip-gallery--has-arrows {
  padding-top: 40px;
}
.cg-strip-gallery .cg-gallery-item.swiper-slide {
  width: auto;
  line-height: 1px;
}
.cg-strip-gallery .cg-gallery-image-part {
  width: auto;
  height: 100%;
}
.cg-strip-gallery .cg-gallery-image {
  height: auto;
  width: auto;
  max-height: var(--h, 200px);
}
.cg-strip-gallery .cg-gallery-progressbar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
}
.cg-strip-gallery.cg-strip-gallery--mobile {
  height: var(--mobile-h, var(--h));
}
.cg-strip-gallery.cg-strip-gallery--mobile .cg-gallery-image {
  max-height: var(--mobile-h, var(--h));
}

.cg-strip-gallery-arrows {
  position: absolute;
  top: 0;
  right: 0;
}

.cg-strip-gallery-button {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: none;
  background: rgba(0, 0, 0, 0.05) no-repeat center center;
  background-size: 14px auto;
  transition: all 0.2s;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.cg-strip-gallery-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.cg-strip-gallery-button.swiper-button-disabled {
  opacity: 0.4;
  cursor: auto;
}

.cg-strip-gallery-button--prev {
  background-image: url("chevron-left.svg");
  background-position: calc(50% - 1px) center;
}

.cg-strip-gallery-button--next {
  background-position: calc(50% + 1px) center;
  background-image: url("chevron-right.svg");
}

.cg-single-gallery {
  position: relative;
  margin-right: auto;
  display: inline-block;
  max-width: 100%;
  width: var(--w, 100%);
  line-height: 1px;
}
.cg-single-gallery .cg-gallery-item, .cg-single-gallery .cg-gallery-image {
  max-width: 100%;
  height: auto;
}
.cg-single-gallery.cg-single-gallery--mobile {
  width: var(--mobile-w, var(--w, 100%));
}

.cg-single-gallery-count {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 5px 30px 5px 8px;
  line-height: 1;
  font-size: 14px;
  color: #333;
  border-radius: 2px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.5) url("photo.svg") no-repeat right 6px center;
  background-size: auto 16px;
}

.cg-swiper-gallery {
  width: var(--w, 100%);
  position: relative;
  max-width: 100%;
}
.cg-swiper-gallery .cg-gallery-item {
  line-height: 1px;
}
.cg-swiper-gallery .cg-gallery-item, .cg-swiper-gallery .cg-gallery-image {
  max-width: 100%;
  height: auto;
}

.cg-swiper-gallery--cropped .cg-gallery-item, .cg-swiper-gallery--cropped .cg-gallery-image {
  width: var(--w);
  height: var(--h);
  object-fit: cover;
}
.cg-swiper-gallery--cropped .cg-swiper-gallery-body {
  height: var(--h);
}
.cg-swiper-gallery--cropped.cg-swiper-gallery--has-labels .cg-gallery-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cg-swiper-gallery--cropped.cg-swiper-gallery--has-labels .cg-gallery-item, .cg-swiper-gallery--cropped.cg-swiper-gallery--has-labels .cg-swiper-gallery-body {
  height: calc(var(--h) + 32px);
}

.cg-swiper-gallery--fixed .cg-gallery-item {
  width: var(--w);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--fixed-bg, rgba(0, 0, 0, 0.2));
}
.cg-swiper-gallery--fixed .cg-gallery-label {
  align-self: stretch;
  padding-left: 10px;
  padding-right: 10px;
}

.cg-swiper-gallery--mobile {
  width: var(--mobile-w, var(--w, 100%));
}
.cg-swiper-gallery--mobile.cg-swiper-gallery--fixed .cg-gallery-item {
  width: var(--mobile-w, var(--w, 100%));
}
.cg-swiper-gallery--mobile.cg-swiper-gallery--cropped .cg-swiper-gallery-body {
  height: var(--mobile-h, var(--h));
}
.cg-swiper-gallery--mobile.cg-swiper-gallery--cropped .cg-gallery-item, .cg-swiper-gallery--mobile.cg-swiper-gallery--cropped .cg-gallery-image {
  width: var(--mobile-w, var(--w));
  height: var(--mobile-h, auto);
  object-fit: cover;
}

.cg-swiper-gallery--has-dots {
  padding-bottom: 24px;
}

.cg-swiper-gallery-body {
  position: relative;
}

.cg-swiper-gallery-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 18px;
  line-height: 1px;
}

.cg-swiper-gallery-dots .swiper-pagination-bullet {
  margin-left: 0;
  width: 12px;
  height: 18px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.cg-swiper-gallery-dots .swiper-pagination-bullet::after {
  display: inline-block;
  width: 6px;
  height: 6px;
  content: "";
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.4);
}
.cg-swiper-gallery-dots .swiper-pagination-bullet-active::after {
  background-color: rgba(0, 0, 0, 0.9);
}

.cg-swiper-gallery-button {
  width: 40px;
  height: 40px;
  border: none;
  position: absolute;
  top: calc(50% - 20px);
  background: rgba(255, 255, 255, 0.4) no-repeat center center;
  background-size: 18px auto;
  transition: all 0.2s;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  z-index: 100;
}
.cg-swiper-gallery-button:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.cg-swiper-gallery-button.swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
}

.cg-swiper-gallery-button--prev {
  background-image: url("chevron-left.svg");
  left: 3px;
}

.cg-swiper-gallery-button--next {
  background-image: url("chevron-right.svg");
  right: 3px;
}

.cg-swiper-gallery-thumbnails {
  height: var(--thumbnail-h, 50px);
  margin-top: 2px;
}
.cg-swiper-gallery-thumbnails > .swiper-container, .cg-swiper-gallery-thumbnails > .swiper-container > .swiper-wrapper {
  height: 100%;
}
.cg-swiper-gallery-thumbnails > .swiper-container {
  padding-top: 1px;
  padding-bottom: 1px;
}
.cg-swiper-gallery-thumbnails .swiper-slide {
  width: auto;
}
.cg-swiper-gallery-thumbnails .cg-gallery-image-part {
  height: 100%;
}
.cg-swiper-gallery-thumbnails .cg-gallery-item--playable .cg-gallery-image-part::after {
  height: 32px;
  width: 32px;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  max-width: 80%;
  max-height: 80%;
}
.cg-swiper-gallery-thumbnail {
  display: inline-block;
  margin-right: 2px;
  height: 100%;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.1);
}

.cg-swiper-gallery-thumbnail-image {
  height: 100%;
  width: auto;
}

.cg-swiper-gallery-thumbnail--active {
  position: relative;
}
.cg-swiper-gallery-thumbnail--active::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: solid 2px red;
  z-index: 22;
  pointer-events: none;
}

.cg-mosaic-gallery.cg-mosaic-gallery-grid {
  display: grid;
  grid-template-columns: var(--cols, 1fr);
  grid-template-rows: var(--rows, auto);
  gap: var(--spacing, 2px);
  max-width: var(--width, none);
}
.cg-mosaic-gallery.cg-mosaic-gallery--mobile {
  grid-template-columns: var(--mobile-cols, 1fr);
  grid-template-rows: var(--mobile-rows, 1fr);
  gap: var(--mobile-spacing, 2px);
  max-width: var(--mobile-width, none);
}
.cg-mosaic-gallery .cg-mosaic-item {
  grid-column-start: var(--col, 1);
  grid-column-end: span var(--col-span, 1);
  grid-row-start: var(--row, 1);
  grid-row-end: span var(--row-span, 1);
  background: var(--bg, transparent);
  line-height: 1px;
}
.cg-mosaic-gallery.cg-mosaic-gallery--mobile .cg-mosaic-item {
  grid-column-start: var(--mobile-col, 1);
  grid-column-end: span var(--mobile-col-span, 1);
  grid-row-start: var(--mobile-row, 1);
  grid-row-end: span var(--mobile-row-span, 1);
}
.cg-mosaic-gallery .cg-mosaic-item--centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cg-mosaic-gallery .cg-mosaic-item--centered .cg-gallery-item {
  height: auto;
}
.cg-mosaic-gallery .cg-mosaic-item--filled .cg-gallery-image-part {
  flex: 1 1 auto;
  width: 100%;
}
.cg-mosaic-gallery .cg-mosaic-item--filled .cg-gallery-item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cg-mosaic-gallery .cg-mosaic-item--filled .cg-gallery-label {
  flex: 0 0 auto;
}
.cg-mosaic-gallery .cg-mosaic-item--filled .cg-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cg-mosaic-gallery .cg-gallery-item {
  height: 100%;
  line-height: 1px;
}
.cg-mosaic-gallery .cg-gallery-image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
.cg-mosaic-gallery.cg-mosaic-gallery--mobile .cg-mosaic-hide-mobile {
  display: none;
}
.cg-mosaic-gallery:not(.cg-mosaic-gallery--mobile) .cg-mosaic-hide-desktop {
  display: none;
}

.cg-gallery-layout--ib {
  display: inline-block;
}

.cg-gallery-layout--default.cg-gallery-margin {
  margin-top: var(--layout-margin, 3em);
  margin-bottom: var(--layout-margin, 3em);
}

.cg-gallery-layout--ac {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cg-gallery-layout--ac.cg-gallery-margin {
  margin-top: var(--layout-margin, 3em);
  margin-bottom: var(--layout-margin, 3em);
}

.cg-gallery-layout--al {
  display: block;
  margin-left: 0;
  margin-right: auto;
}
.cg-gallery-layout--al.cg-gallery-margin {
  margin-top: var(--layout-margin, 3em);
  margin-bottom: var(--layout-margin, 3em);
}

.cg-gallery-layout--ar {
  display: block;
  margin-left: auto;
  margin-right: 0;
}
.cg-gallery-layout--ar.cg-gallery-margin {
  margin-top: var(--layout-margin, 3em);
  margin-bottom: var(--layout-margin, 3em);
}

.cg-gallery-layout--fl {
  display: block;
  float: left;
}
.cg-gallery-layout--fl.cg-gallery-margin {
  margin-top: var(--layout-margin, 3em);
  margin-bottom: var(--layout-margin, 3em);
  margin-right: var(--layout-margin, 3em);
}

.cg-gallery-layout--fr {
  display: block;
  float: right;
}
.cg-gallery-layout--fr.cg-gallery-margin {
  margin-top: var(--layout-margin, 3em);
  margin-bottom: var(--layout-margin, 3em);
  margin-left: var(--layout-margin, 3em);
}

.cg-gallery--before-load .cg-gallery-effect-target {
  opacity: 0;
  visibility: hidden;
}

.cg-gallery--entering .cg-gallery-effect-target {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s, visibility 0.4s;
  transition-delay: calc((var(--number, 1) - 1) * 0.035s);
}

.cg-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 3em;
  --gallery-pagination-button-size: 32px;
  --gallery-pagination-button-bg: rgba(0, 0, 0, 0.1);
  --gallery-pagination-button-fg: black;
  --gallery-pagination-button-hover-bg: rgba(0, 0, 0, 0.15);
  --gallery-pagination-button-hover-fg: black;
  --gallery-pagination-button-disabled-bg: rgba(0, 0, 0, 0.05);
  --gallery-pagination-button-disabled-fg: rgba(0, 0, 0, 0.3);
  --gallery-pagination-button-active-bg: rgba(0, 0, 0, 0.25);
  --gallery-pagination-button-active-fg: black;
  --gallery-pagination-button-active-font-weight: bold;
  --gallery-pagination-button-outline: #000099;
}

.cg-pagination__pages {
  display: flex;
  gap: 4px;
}

.cg-pagination__button {
  appearance: none;
  border: none;
  box-shadow: none;
  font-size: inherit;
  font-family: inherit;
  height: var(--gallery-pagination-button-size);
  box-sizing: border-box;
  padding-inline: calc(var(--gallery-pagination-button-size) / 2);
  color: var(--gallery-pagination-button-fg);
  background: var(--gallery-pagination-button-bg);
}
.cg-pagination__button:not(:disabled) {
  cursor: pointer;
}
.cg-pagination__button:hover:not(:disabled) {
  background: var(--gallery-pagination-button-hover-bg);
  color: var(--gallery-pagination-button-hover-fg);
}
.cg-pagination__button:disabled {
  background: var(--gallery-pagination-button-disabled-bg);
  color: var(--gallery-pagination-button-disabled-fg);
}
.cg-pagination__button:focus-visible {
  outline: solid 2px var(--gallery-pagination-button-outline);
}

.cg-pagination__page {
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  border: none;
  box-shadow: none;
  height: var(--gallery-pagination-button-size);
  box-sizing: border-box;
  padding-inline: 6px;
  min-width: var(--gallery-pagination-button-size);
  color: var(--gallery-pagination-button-fg);
  background: var(--gallery-pagination-button-bg);
  cursor: pointer;
}
.cg-pagination__page:hover {
  background: var(--gallery-pagination-button-hover-bg);
  color: var(--gallery-pagination-button-hover-fg);
}
.cg-pagination__page:focus-visible {
  outline: solid 2px var(--gallery-pagination-button-outline);
}

.cg-pagination__page--active {
  color: var(--gallery-pagination-button-active-fg);
  background: var(--gallery-pagination-button-active-bg);
  font-weight: var(--gallery-pagination-button-active-font-weight);
}

@media only screen and (max-width: 750px) {
  .cg-pagination {
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
}