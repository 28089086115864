.cg-gallery--before-load {

	.cg-gallery-effect-target {
		opacity: 0;
		visibility: hidden;
	}

}

.cg-gallery--entering {

	.cg-gallery-effect-target {
		opacity: 1;
		visibility: visible;
		transition: opacity 0.4s, visibility 0.4s;
		transition-delay: calc((var(--number, 1) - 1) * 0.035s);
	}

}
