.cg-pagination {
	display: flex;
	justify-content: space-between;
	margin-top: 3em;

	--gallery-pagination-button-size: 32px;
	--gallery-pagination-button-bg: #{rgba(black, 0.1)};
	--gallery-pagination-button-fg: black;

	--gallery-pagination-button-hover-bg: #{rgba(black, 0.15)};
	--gallery-pagination-button-hover-fg: black;

	--gallery-pagination-button-disabled-bg: #{rgba(black, 0.05)};
	--gallery-pagination-button-disabled-fg: #{rgba(black, 0.3)};

	--gallery-pagination-button-active-bg: #{rgba(black, 0.25)};
	--gallery-pagination-button-active-fg: black;
	--gallery-pagination-button-active-font-weight: bold;

	--gallery-pagination-button-outline: #000099;

}

.cg-pagination__pages {
	display: flex;
	gap: 4px;
}

.cg-pagination__button {
	appearance: none;
	border: none;
	box-shadow: none;
	font-size: inherit;
	font-family: inherit;
	height: var(--gallery-pagination-button-size);
	box-sizing: border-box;
	padding-inline: calc(var(--gallery-pagination-button-size) / 2);
	color: var(--gallery-pagination-button-fg);
	background: var(--gallery-pagination-button-bg);

	&:not(:disabled) {
		cursor: pointer;
	}

	&:hover:not(:disabled) {
		background: var(--gallery-pagination-button-hover-bg);
		color: var(--gallery-pagination-button-hover-fg);
	}

	&:disabled {
		background: var(--gallery-pagination-button-disabled-bg);
		color: var(--gallery-pagination-button-disabled-fg);
	}

	&:focus-visible {
		outline: solid 2px var(--gallery-pagination-button-outline);
	}
}

.cg-pagination__page {
	appearance: none;
	font-size: inherit;
	font-family: inherit;
	border: none;
	box-shadow: none;
	height: var(--gallery-pagination-button-size);
	box-sizing: border-box;
	padding-inline: 6px;
	min-width: var(--gallery-pagination-button-size);
	color: var(--gallery-pagination-button-fg);
	background: var(--gallery-pagination-button-bg);

	cursor: pointer;

	&:hover {
		background: var(--gallery-pagination-button-hover-bg);
		color: var(--gallery-pagination-button-hover-fg);
	}

	&:focus-visible {
		outline: solid 2px var(--gallery-pagination-button-outline);
	}

}

.cg-pagination__page--active {
	color: var(--gallery-pagination-button-active-fg);
	background: var(--gallery-pagination-button-active-bg);
	font-weight: var(--gallery-pagination-button-active-font-weight);
}

@media only screen and (max-width: 750px) {
	.cg-pagination {
		flex-direction: column;
		gap: 24px;
		align-items: center;
	}
}
